<template>
    <v-container fluid>

        <v-toolbar elevation="1" dark color="primary">
            <v-toolbar-title>
                Estadística
            </v-toolbar-title>
        </v-toolbar>

        <v-sheet elevation="2">
            <v-row no-gutters>
                <v-col xs="12" sm="12" md="3" lg="3" xl="3" cols="12">
                    <v-treeview
                        dense
                        class="pa-2"
                        :items="reportes"
                        open-on-click
                        activatable
                        hoverable
                        selected-color
                        color="green"
                        :active.sync="reporteActivo"
                    />
                </v-col>

                <v-col xs="12" sm="12" md="9" lg="9" xl="9" cols="12">
                    <transition
                        name="fade"
                        mode="out-in"
                    >
                        <router-view/>
                    </transition>
                </v-col>
            </v-row>
        </v-sheet>
    </v-container>
</template>

<script>
export default {


    data:()=>({
        reporteActivo:[],
        reportes:[
            /*
            {
                id:10,
                name:'Indicas',
                children:[
                    {
                        id:101,
                        name: 'Tiempos de atención URGENCIAS',
                    },
                    {
                        id:102,
                        name: 'Tiempos de atención CONSULTA',
                    },
                ]
            },
            */
            {
                id:20,
                name:'Estadistica',
                children:[
                    {
                        id:201,
                        name: 'S.I.S.',
                    },
                    {
                        id:202,
                        name: 'Estudios',
                    },
                    {
                        id:203,
                        name: 'Egresos',
                    },
                    {
                        id:204,
                        name: 'Urgencias',
                    },
                    {
                        id:205,
                        name: 'Lesiones',
                    },
                    {
                        id:206,
                        name: 'Defunciones',
                    },
                ]
            },
            {
                id:30,
                name:'Reportes',
                children:[
                    {
                        id:301,
                        name: 'Movimientos',
                    },
                    {
                        id:302,
                        name: 'Espera CONSULTA',
                    },
                    {
                        id:303,
                        name: 'Espera URGENCIAS',
                    },
                    {
                        id:304,
                        name: 'Cirugias',
                    },
                ]
            },
        ]
    }),
    methods:{
    },
    watch:{
        reporteActivo(){

            //console.log(this.reporteActivo[0])
            switch(this.reporteActivo[0]){
                //case 101:
                //    this.$router.push({path:'/estadistica/indicasurgencias'})
                //    break

                //case 102:
                //    this.$router.push({path:'/estadistica/indicasconsulta'})
                //    break

                case 201:
                    this.$router.push({path:'/estadistica/consutasis'})
                    break
                case 202:
                    this.$router.push({path:'/estadistica/estudiosis'})
                    break
                case 301:
                    this.$router.push({path:'/estadistica/estadisticaPacientes/listado'})
                    break
                case 302:
                    this.$router.push({path:'/estadistica/estadisticaPacientes/esperaConsulta'})
                    break
                case 303:
                    this.$router.push({path:'/estadistica/estadisticaPacientes/esperaUrgencias'})
                    break
                case 304:
                    this.$router.push({path:'/estadistica/estadisticaPacientes/cirugias'})
                    break
            }
        }
    },
}
</script>

<style>

</style>